import {
	GET_TERMINAL_CHART_HISTORY_REQUEST,
	GET_TERMINAL_CHART_HISTORY_FAILURE,
	GET_TERMINAL_CHART_HISTORY_SUCCESS,
} from '../../../actions/types'
import Store from '../../../store'
//import { serverError } from '../../../actions/handleActions'
import { fetchToAPI } from '../../../actions/optionsApp'
import Map from 'lodash/map'
import Includes from 'lodash/includes'


export default {

    onReady: cb => {

        console.log('[onReady]: Method call');

        setTimeout(() => cb(), 0)
    },

    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {

        console.log('[resolveSymbol]: Method call', symbolName);

		
        let symbol = {
			name: `${window.location.hostname}:${symbolName}`, 
            "description": '',
            "type": '',
            "session": '24x7',
            "timezone": 'Europe/Moscow',
            "exchange": '',
            "minmov": 1,
            "pricescale": 10000,
            "has_intraday": true,
            "has_seconds": true,
            "seconds_multipliers": [
                "1"
            ],
            "intraday_multipliers": [
                "1"
            ],
            "supported_resolutions": ['1', '3', '5', '15', '30', '60', '120', '240', '1D'],
            "supports_marks": true,
            "data_status": "streaming"
        }

        setTimeout(() => onSymbolResolvedCallback(symbol), 0)
    },

    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback,
    ) => {

        console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);

		window.onRealtimeCallback = onRealtimeCallback;
    },

    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		
		delete window.onRealtimeCallback;
    },

    getBars: function(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
        const { from, to, firstDataRequest } = periodParams;

        console.log('[getBars]: Method call', symbolInfo, resolution, from, to);

        if(firstDataRequest) {

			let multiplier = 1
			let timespan = 'm'
	
			console.log(symbolInfo)
			console.log("test: " + resolution)
	
			if( resolution === 'W' || resolution === '1W' ) timespan = 'w'
			if( resolution === 'D' || resolution === '1D' ) timespan = 'd'
			if( Includes(['1', '3', '5', '15', '30', '45'], resolution) ){
				multiplier = parseInt( resolution )
				timespan = 'm'
			}
			if( Includes(['60', '120', '180', '240'], resolution) ){
				timespan = 'h'
				multiplier = parseInt( resolution ) / 60
			}

            Store.dispatch(fetchToAPI({
				url: '/terminal/history',
				data: {
					to: to * 1000,
					from: from * 1000,
					timespan: timespan,
					multiplier: multiplier,
					chart_id: localStorage.chartId
				},
				request: res => {
					return { 
						type: GET_TERMINAL_CHART_HISTORY_REQUEST,
						payload: res
					}
				},
				failure: res => {
					return { 
						type: GET_TERMINAL_CHART_HISTORY_FAILURE,
						payload: res
					}
				},
				success: res => {
	
					let bars = Map( res, ( t ) => {
						return {
							time: t[6],
							close: parseFloat(t[4]), 
							open: parseFloat(t[1]), 
							high: parseFloat(t[2]), 
							low: parseFloat(t[3]), 
							volume: parseFloat(t[5]),
						}
					})
	
					console.log(bars)
	
					onHistoryCallback(bars, {noData: false})
	
					return { 
						type: GET_TERMINAL_CHART_HISTORY_SUCCESS,
						payload: res
					}
				},
				serverError: err => {
					
				},
			}))
        }

    },
}