import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from '../../header'
import Footer from '../../footer'

import {
    resetPassword
} from '../../../../actions/authResetPassword'

import './index.scss'

class ResetPassword extends Component {

		constructor(props) {
			super(props);
	
			this.state = {
				viewAuth: false,
				headerBackground: false,
			};
	
		}
	
		componentDidMount() {
			this.props.resetPassword(this.props.match.params)
		}
	
		render() {
			const {
				viewAuth,
			} = this.state;
	
			const { includeToCabinet } = this.props;
	
			return (
				<div className="main">
					{!includeToCabinet ?
						<Header 
							viewAuth={viewAuth}
							openAuth={() => this.setState({viewAuth: !viewAuth})} 
						/>
					: false}
					<section className="merchant">
						<div className="container">
							<div className="merchant__wrapper">
								<div className="merchant__icon">
									{!this.props.reset.error ? <i className="fa fa-check-circle success_icon" aria-hidden="true"></i> : <i className="fa fa-times-circle fail_icon" aria-hidden="true"></i>}
								</div>
								<div className="merch_mess">
									<p>{this.props.reset.description}</p>
								</div>
								<a className="btn btn_border link_back" href="/cabinet/profile">Вернуться назад</a>
							</div>
						</div>
					</section>
				{!includeToCabinet ? <Footer /> : false}

				</div>
			)
		}
	
	}
	
const mapStateToProps = (state) => ({
	reset: state.authResetPassword
})

const mapDispatchToProps = (dispatch) => ({
    resetPassword: (data) => dispatch(resetPassword(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
