import React from 'react'
import { connect } from 'react-redux'
//import config from '../../../config'
import { NavLink } from 'react-router-dom'

import './index.scss'

class Footer extends React.Component {

	render() {
		return (
			<footer className="footer">

				<div className="footer__top">
					<div className="container">
						<div className="footer__top-cover">
							<div className="footer__top-title">Используйте все преимущества самостоятельной торговли на криптовалютных рынках.</div>
							<div className="footer__top-cta">
								<NavLink className="btn btn_border footer_cta_btn" to="/login">Начать торговлю</NavLink>
							</div>
						</div>
					</div>
				</div>
				<div className="footer__bot">
					<div className="container">
						<div className="footer__bot-cover">
							<div className="footer_row footer__bot-title">
								<div className="footer__logo">
								</div>

								<div className="footer__social">		
									<a href="https://t.me/quotexs_pro" target="_blank" rel="noopener noreferrer"><span className="footer__social-tg"></span></a>				
								</div>
							</div>
							<div className="footer_mail">support@qxsbroker.club</div>
							<div className="footer_row footer__bot-nav">
								<div className="nav_col">
									<div className="menu_bot-title">
										<NavLink to="/affiliate">Партнерская программа</NavLink>
									</div>
									<ul className="menu_bot">
										<li>
											<NavLink to="/affiliate">Регистрация</NavLink>
										</li>
									</ul>
								</div>
								<div className="nav_col">
									<div className="menu_bot-title">FAQ</div>
									<ul className="menu_bot">
										<li><NavLink to="/faq">Основные вопросы</NavLink></li>
										<li><NavLink to="/faq">Финансовые вопросы</NavLink></li>
										<li><NavLink to="/faq">Верификация</NavLink></li>
									</ul>
								</div>
								<div className="nav_col">
									<div className="menu_bot-title">О нас</div>
									<ul className="menu_bot">
										<li>
											<NavLink to="/about">Основная информация</NavLink>
										</li>
									</ul>
								</div>
								<div className="nav_col">
									<ul className="menu_bot privacy">
										<li>
											<a href="/docs/privacy-policy.pdf">Политика приватности</a>
										</li>
										<li>
											<a href="/docs/user-agreement.pdf">Пользовательское соглашение</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="footer_row footer__bot-info">
								<div className="info">
									<p style={{color: "#C62828"}}>Предупреждение о рисках: торговля на Digital Trading и с использованием заемных
										финансовых
										инструментов сопряжена со значительным риском и может привести к
										потере вашего инвестированного капитала. Вы не должны инвестировать больше, чем
										можете
										позволить себе потерять, и должны осознавать все связанные с этим риски.
										Торговля с использованием заемных средств не подходит для всех инвесторов.
										Торговля
										продуктами без привлечения заемных средств, такими как акции, также
										сопряжена с риском, поскольку стоимость акции может как падать, так и расти, что
										может
										означать что вы можете получить меньше средств даже в случае выйгрыша, чем
										вы изначально вложили. Успешные результаты в прошлом не являются гарантией
										будущих
										результатов. Перед торговлей, пожалуйста, примите во внимание ваш уровень
										опыта, инвестиционные цели и, при необходимости, обратитесь за независимой
										финансовой
										консультацией. Клиент обязан выяснить, разрешено ли ему / ей пользоваться
										услугами бренда Quotex на основании требований законодательства страны его
										проживания.
									</p>
									<p>Copyright © 2021 <span>Quotex</span>. Все права защищены</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	}

}

const mapStateToProps = (state) => ({
	app: state.app
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);