import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
    increase,
    getIncreaseInfo,
    getPaymentTypes
} from '../../../../actions/payment'
import {check} from '../../../../actions/promocodes'


import './index.scss'


class PaymentIncrease extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisiblePromocode: false,
            isOpenInfo: null,
            topOpenBlock: 0,
            topMouse: 0,
            orderAmount: null,
            selected: 0,
            open: null,
            promocodeName: '',
        };

        this.info = [
            {
                title: "Установлен ли размер минимальной суммы, которую я могу зачислить на счет при регистрации?",
                text: `<p>
                            Преимущество торговой платформы Компании в том числе в том, что Вам не придется зачислять 
                            на счет крупные суммы. Начать торговлю Вы можете, инвестировав незначительную сумму денег.  
                            Минимальный депозит составляет 50 долларов США.
                       </p>`
            },
            {
                title: "Нужно ли пополнять счет на торговой платформе и как часто это нужно делать?",
                text: `<p>
                        Для работы с Digital Trading Вам необходимо открыть личный счет. Чтобы заключать реальные сделки Вам безусловно нужно будет его пополнять на сумму приобретаемых опционов.
                        </p>
                        <p>
                        Начать торговлю без денежных средств можно только используя учебный счет компании (демо-счет). Такой счет является бесплатным и создан для демонстрации функционирования торговой платформы. С помощью такого счета Вы можете потренироваться в приобретении digital опционов, понять основные принципы торговли, протестировать различные методики и стратегии, или же оценить уровень Вашей интуиции.
                        </p>`
            },
            {
                title: "Как можно пополнить счет?",
                text: `<p>
                            Сделать это очень просто. Процедура займет пару минут.
                       </p>
                       <p>
                            1) Откройте окно исполнения сделки и нажмите зеленую кнопку «Пополнить» в правом верхнем углу вкладки.
                       </p>
                       <p>
                            Пополнить счет можно также через Ваш Личный кабинет, нажав кнопку «Пополнить» в профиле аккаунта.
                       </p>
                       <p>
                            2) После необходимо выбрать способ пополнения счета (Компания предлагает массу удобных способов, которые доступны Клиенту и отображаются в его личном кабинете).
                       </p>
                       <p>
                            3) Далее укажите валюту, в которой будет производиться пополнение счета, и соответственно валюту самого счета.
                       </p>
                       <p>
                            4) Введите размер пополнения.
                       </p>
                       <p>
                            5) Заполните предложенную форму, введя запрашиваемые платежные данные.
                       </p>
                       <p>
                            6) Произведите платеж.
                       </p>
                      `
            },
            {
                title: "Существует ли комиссия при пополнении счета?",
                text: `
                      <p>
                            Нет. Компания не взымает комиссию ни за операцию пополнения счета, ни за вывод средств.
                      </p>
                      <p>
                            Однако, стоит учитывать, что платежные системы могут взимать свои комиссии и использовать внутренний курс конвертации валют.
                      </p>
                      `
            }
        ]
    }

    componentDidMount() {
        this.props.getIncreaseInfo()
        this.props.getPaymentTypes()
    }


    componentDidUpdate(prevProps, prevState) {
        if(prevProps.payment.min_order_amount !== this.props.payment.min_order_amount && this.state.orderAmount === null) {
            this.setState({orderAmount: this.props.payment.min_order_amount})
        }
    }

    createLinkPayment() {
        const {orderAmount, selected, open} = this.state;

        const {promocode} = this.props.payment;
        
        if (this.props.payment.types[selected].type != null) {
            this.props.increase({
                order_amount: +orderAmount,
                promocode_id: promocode.id,
                type: this.props.payment.types[selected].type
            });
        } else {
            this.setState({open: selected === open ? null : selected})
        }

        
    }

    promocodeCheck() {
        const {promocodeName} = this.state;

        this.props.check({
            promocode_name: promocodeName
        });
    }

    render() {
        const {
            orderAmount,
            promocodeName,
            isVisiblePromocode,
            isOpenInfo,
            topOpenBlock,
            selected,
            open,
            topMouse
        } = this.state;

        const {
            url,
            promocode,
            is_payment,
            min_increase_amount,
            min_order_amount,
            types
        } = this.props.payment;
        
        if (url) {
            window.open(url, '_self');
            delete (this.props.payment.url);
        }

        let bonus = promocode.success ? +promocode.payment_bonus : 0;
        let result = +orderAmount + bonus;
/*


*/
        return (
            <div className="increase">
                <div className="refill__row">
                    <div className="content__mid-left">
                            <div className="content__mid-col withdraw__col-left">
                                {
                                    open !== null ? 
                                    <div className="data_block">
                                        <div className="data_block-title">{types[open].name}</div>
                                        <div className="withdraw__description" dangerouslySetInnerHTML={{__html: types[open].description}}></div><br/>
                                        <div className="withdraw__label">Подтверждение перевода происходит автоматически в течение 30 минут, после средства будут зачислены на ваш счет.</div>
                                        <br/><br/>
                                        <div className="withdraw__labell">Убедитесь, что вы выбираете сеть для перевода, соответствующую адресу кошелька в этой сети. В противном случае активы могут быть потеряны!<br/><br/>Например, при переводе USDT вы выбираете сеть TRC20, важно, чтобы адрес кошелька брокера был указан именно в этой сети.</div>
                                    </div>
                                    :
                                    <div className="data_block">
                                        {
                                            is_payment ? 
                                            <div>
                                                <div className="data_block-cards">
                                                    {
                                                        types.map((item, key) => {
                                                            return(
                                                                <button key={key} onClick={()=> this.setState({ selected: key }) } className={selected === key ? "card_item selected" : "card_item"}>
                                                                    <div className="card_item__icon">
                                                                        {
                                                                            item.type != null ? 
                                                                                null
                                                                            : 
                                                                            <img style={{"width": "100%" }} src={`/images/icons/payment/${item.name.toLowerCase()}.png`} alt={item.name}/>
                                                                        }
                                                    
                                                                    </div>
                                                                    <div className="card_item__text">{item.name}</div>
                                                                </button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>: null 
                                        }
                                        <div className="withdraw__label">Выберите платежную систему для пополнения счета</div>
                                    </div>
                                }
                                
                            </div>
                        </div>

                        <div className="content__mid-right increase__mid-right">
                        {
                            open !== null ? null :
                                <div className="content__mid-col refill__col-right">
                                    <div className="data_block">
                                        <div className="data_block-title">Детали платежа:</div>
                                        <div className="data_block-inputs">
                                            <div className="input_cover">
                                                <div className="input_label">Сумма</div>
                                                <input type="number"
                                                    value={orderAmount}
                                                    onChange={e => this.setState({orderAmount: e.target.value})}/>
                                                <div className="input_placeholder">USD</div>
                                            </div>
                                        </div>
                                        <div className="data_block-actions">
                                            <div className="payment_templates">
                                                <button className="payment_templates__btn"
                                                        onClick={() => this.setState({orderAmount: 150})}>150 $
                                                </button>
                                                <button className="payment_templates__btn"
                                                        onClick={() => this.setState({orderAmount: 200})}>200 $
                                                </button>
                                                <button className="payment_templates__btn"
                                                        onClick={() => this.setState({orderAmount: 300})}>300 $
                                                </button>
                                                <button className="payment_templates__btn"
                                                        onClick={() => this.setState({orderAmount: 500})}>500 $
                                                </button>
                                            </div>
                                            <div className="chbx_btn_cover">
                                                <input className="chbx_promocode" type="checkbox" id="promocode"
                                                    onClick={() => this.setState({isVisiblePromocode: !isVisiblePromocode})}/>
                                                <label htmlFor="promocode">
                                                    Использовать<br/>бонус</label>
                                            </div>
                                        </div>
                                        {
                                            !isVisiblePromocode ? null :
                                                <div className="data_block-inputs promo_container">
                                                    <div className="input_cover input_cover__promo">
                                                        <div className="input_label">Промо-код</div>
                                                        <input type="text"
                                                            defaultValue={promocodeName}
                                                            onChange={e => this.setState({promocodeName: e.target.value})}/>
                                                        <button onClick={() => this.promocodeCheck()} className="promo_btn">Применить промо-код</button>
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                    <div className="refill_desc">
                                        <p>Минимальная сумма депозита: {parseFloat(min_order_amount).toFixed(2)} $</p>
                                        <p>Минимальная сумма вывода: {parseFloat(min_increase_amount).toFixed(2)} $</p>
                                        <p>Быстрый вывод средств с вашего счета</p>
                                    </div>
                                </div>
                            }

                            <div className="content__mid-col refill__col-right">
                                <div className="data_block">
                                    <div className="data_block-refill">
                                        <div className="refill__item post_info">
                                            <div className="accInfo__item__title">Вы платите:</div>
                                            <div className="accInfo__item__value">{parseFloat(+orderAmount).toFixed(2)} $</div>
                                        </div>
                                        <div className="refill__item get_info">
                                            <div className="accInfo__item__title">Вы получаете:</div>
                                            <div className="accInfo__item__value">{parseFloat(result).toFixed(2)} $</div>
                                        </div>
                                        <button className="btn btn_blue refill__btn" onClick={() => this.createLinkPayment()}>{open === null ? "Пополнить" : "Назад"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="content__mid-bottom increase__mid-bottom">
                    <div className="content__mid-col refill__questions">
                        <ul className="refill__questions-tabs">
                            {
                                this.info.map((item, key) => {
                                    return (
                                        <li key={key}>
                                            <button
                                                onClick={() => this.setState({isOpenInfo: key, topOpenBlock: topMouse})}
                                                onMouseMove={(e) => {
                                                    this.setState({topMouse: e.pageY})
                                                }}>{item.title}</button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    {
                        this.info[isOpenInfo] === undefined ? null :
                            <div className="content__mid-col refill__answers"
                                 style={{'position': 'absolute', 'top': `${topOpenBlock}px`, 'left': "480px"}}>
                                <div className="tab_container">
                                    <div className="refill__tab_content" id="tab3_refill">
                                        <div className="refill__answers-item">
                                            <div className="item__header">
                                                <div className="header__left">
                                                    <div className="item__header-icon"><img src="/images/icons/arrow_left.svg"
                                                                                            alt="arrow_left.svg"/></div>
                                                    <div
                                                        className="item__header-title">{this.info[isOpenInfo].title}</div>
                                                </div>

                                                <div className="header__right tab_close"
                                                     onClick={() => this.setState({isOpenInfo: null})}>X
                                                </div>
                                            </div>
                                            <div className="item__body"
                                                 dangerouslySetInnerHTML={{__html: this.info[isOpenInfo].text}}>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }

}

PaymentIncrease.propTypes = {
    payment: PropTypes.object.isRequired,
    increase: PropTypes.func.isRequired,
    check: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    payment: state.payment,
    verify: state.terminal.user.verify
})

const mapDispatchToProps = (dispatch) => ({
    check: (data) => dispatch(check(data)),
    increase: (data) => dispatch(increase(data)),
    getIncreaseInfo: (data) => dispatch(getIncreaseInfo(data)),
    getPaymentTypes: (data) => dispatch(getPaymentTypes(data)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentIncrease);