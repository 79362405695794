import React from 'react'
import moment from 'moment'
import { history } from '../../../store'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'

import 'react-day-picker/lib/style.css'
import {Select} from "../../formElements/select";

class CabinetTable extends React.Component {

	constructor(props) {
		super(props);

	    let activePage = new URLSearchParams(props.location.search).get('number');

	    if(!activePage) {
	    	activePage = 1;
	    	history.replace(this.props.location.pathname + '?number='+activePage);
	    }

	    this.state = {
	    	dateTo: null,
	    	dateFrom: null,
	    	itemsCountPerPage: 10,
	    	activePage: +activePage,
	    	wallet: 'virtual_dollars'
	    };

	    this.handleFromChange = this.handleFromChange.bind(this);
	    this.handleToChange = this.handleToChange.bind(this);
	}

	handlePageChange(activePage) {

		this.setState({activePage: activePage});

		this.requestToServer(activePage);
	}

	handlePageNext() {
		let activePage = this.state.activePage + 1
		if(activePage > Math.ceil(this.props.countItems/this.state.itemsCountPerPage)) return;
		history.replace(this.props.location.pathname + '?number='+activePage);

		this.setState({ activePage });

		this.requestToServer(activePage);
	}

	handlePageBack() {
		let activePage = this.state.activePage - 1
		if(activePage < 1) return;
		history.replace(this.props.location.pathname + '?number='+activePage);

		this.setState({ activePage });

		this.requestToServer(activePage);
	}

	formatDate(date){
	    if (typeof date == "string"){
	        date = new Date(date);
	    }

	    var year = date.getFullYear();
	    var month = (1 + date.getMonth()).toString();
	    month = month.length > 1 ? month : '0' + month;

	    var day = date.getDate().toString();
	    day = day.length > 1 ? day : '0' + day;

	    return year+'-'+month+'-'+day;
	}

	requestToServer(newActivePage, walletType) {
		let { dateFrom, dateTo, activePage, wallet } = this.state;

		dateTo = dateTo ? this.formatDate(dateTo) : null;
		dateFrom = dateFrom ? this.formatDate(dateFrom) : null;

		this.props.requestToServer({
			date_to: dateTo,
			date_from: dateFrom,
			wallet: walletType ? walletType : wallet,
			active_page: newActivePage ? newActivePage : activePage
		});
	}

	componentDidMount() {
		this.requestToServer();
	}

	showFromMonth() {
		const { dateFrom, dateTo } = this.state;

		if (!dateFrom) return;

		if (moment(dateTo).diff(moment(dateFrom), 'months') < 1) {
			this.dateTo.getDayPicker().showMonth(dateFrom);
		}
	}

	handleFromChange(dateFrom) {
		this.setState({ dateFrom });
	}

	handleToChange(dateTo) {
		this.setState({ dateTo }, this.showFromMonth);
	}

	applyFilter() {
		this.requestToServer();
	}

	changeWalletType(value) {
		this.setState({ wallet: value });
		this.requestToServer(null, value);
	}

	render() {
		if(!this.props.isLoaded) return false;

		const { 
			dateTo,
			dateFrom,
			activePage,
			itemsCountPerPage
		} = this.state;

    	const modifiers = { start: dateFrom, end: dateTo };

    	const {
    		thead,
    		tbody,
    		title,
    		filter,
    		countItems
    	} = this.props;

		const tablist = [
			{ value: 'virtual_dollars', name: 'Демо счет' },
			{ value: 'dollars', name: 'Реальный счет' },

		]

		return (
			<div>
				<div className="deals__header">
					<div className="deals__select">
						<Select options={tablist} value='Демо счет' className="dealsAccType_select" id="dealsAccType" onChange={(e)=> this.changeWalletType(e.value)} />

					</div>
					{countItems/itemsCountPerPage > 1 ? 
						<div className="deals__pagination">
							<button className="deals__pagination-prev" onClick={()=> {this.handlePageBack()}}>←&#160;&#160;Предыдущая</button>
							<div className="deals__pagination-value">{`${activePage}/${Math.ceil(countItems/itemsCountPerPage)}`}</div>
							<button className="deals__pagination-next"onClick={()=> {this.handlePageNext()}}>Следущая&#160;&#160;→</button>
						</div> : null
					}
				</div>
				<div className="panel full">
					{title ? <div className="head">{title}</div> : false}
					<div className="body">
						<div className="row">
							{filter !== false && countItems ? 
								<div className="InputFromTo">
							        <DayPickerInput
							          value={dateFrom}
							          placeholder="Начало периода"
							          format="YYYY/MM/DD"
							          theme="dark"
							          formatDate={formatDate}
							          parseDate={parseDate}
							          dayPickerProps={{
							            selectedDays: [dateFrom, { dateFrom, dateTo }],
							            disabledDays: { after: dateTo },
							            toMonth: dateTo,
							            modifiers,
							            numberOfMonths: 1,
							            onDayClick: () => this.dateTo.getInput().focus(),
							          }}
							          onDayChange={this.handleFromChange}
							        />
							        <div className="dash">—</div>
							        <span className="InputFromTo-to">
							          <DayPickerInput
							            ref={el => (this.dateTo = el)}
							            value={dateTo}
							            placeholder="Конец периода"
							            format="YYYY/MM/DD"
							            formatDate={formatDate}
							            parseDate={parseDate}
							            dayPickerProps={{
							              selectedDays: [dateFrom, { dateFrom, dateTo }],
							              disabledDays: { before: dateFrom },
							              modifiers,
							              month: dateFrom,
							              fromMonth: dateFrom,
							              numberOfMonths: 1,
							            }}
							            onDayChange={this.handleToChange}
							          />
							        </span>
							        <button className="btn btn_border apply-filter" onClick={() => this.applyFilter()}>Применить</button>
							    </div>
							: false}
						</div>

						
						<div className="deals__content">
							<div className="deals__table">
								{thead}
								<div className="deals__table-body">{tbody ? tbody : this.emptyTableBody()}</div>
							</div>
						</div>
						{this.props.children}
					</div>
				</div>
			</div>
		)
	}

	emptyTableBody() {
		let { emptyTableBodyText } = this.props;

		emptyTableBodyText = emptyTableBodyText ? emptyTableBodyText : "У Вас еще нет операций";

		return (
			<tr className="empty-content">
				<td colSpan="100%">{emptyTableBodyText}</td>
			</tr>
		)
	}
}

export default CabinetTable;