import React from 'react'
import {connect} from 'react-redux'

import {
    sendFileVerify
} from '../../../actions/profile'

import './index.scss'

class Verify extends React.Component {

    sendFile() {
        var passport = document.getElementById('passport').files[0];
        var card = document.getElementById('card').files[0];
        this.props.sendFileVerify({passport, card})
    }

    render() {
        return (
            <div className="verify">
                    <div className="content__mid-col left_col">
                        {
                            parseInt(this.props.verify) !== 1 ?
                                <div className="data_block">
                                    <div className="data_block-title">Верификация:</div>

                                    <div className="data_block-inputs">
                                        <div className="input_cover">
                                            <div className="input_label">Первая страница паспорта</div>
                                            <input id="passport" type="file"/>
                                        </div>

                                        <div className="input_cover">
                                            <div className="input_label">Лицевая сторона банковской карты</div>
                                            <input id="card" type="file"/>
                                        </div>
                                        <button className="btn btn_border submit_btn" onClick={() => this.sendFile()}>Отправить запрос</button>
                                    </div>

                                    <div className="data_block-content verifi_block">
                                        <p>Для прохождения верификации отправьте запрос</p>
                                        <p>Прикрепите фото вашего паспорта и банковской карты</p>
                                    </div>
                                </div>
                                : 
                                <div className="data_block">
                                    <div className="data_block-title">Верификация:</div>

                                    <div className="data_block-content verifi_block">
                                        <p>Верификация успешно пройдена</p>
                                    </div>
                                </div>
                        }
                    </div>

            </div>
        )
    }

}

Verify.propTypes = {
    //profile: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    verify: state.terminal.user.verify
})

const mapDispatchToProps = (dispatch) => ({
    sendFileVerify: (file) => dispatch(sendFileVerify(file)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
