import React from 'react'
// import { NavLink } from 'react-router-dom'

class Logos extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
		};

	}

	render() {

		return (
			<section className="logos">
				<div className="container">
					<div className="logos__wrapper">
						<ul>
							<li><img style={{width: "75px", opacity: "0.7"}} src="/images/company_icon_01.png" alt="логотип>"/></li>
							<li><img style={{width: "75px", opacity: "0.7"}} src="/images/company_icon_02.png" alt="логотип"/></li>
							<li><img style={{width: "75px", opacity: "0.7"}} src="/images/company_icon_03.png" alt="логотип"/></li>
							<li><img style={{width: "75px", opacity: "0.7"}} src="/images/company_icon_04.png" alt="логотип"/></li>
							<li><img style={{width: "75px", opacity: "0.7"}} src="/images/company_icon_05.png" alt="логотип"/></li>
							<li><img style={{width: "75px", opacity: "0.7"}} src="/images/company_icon_06.png" alt="логотип"/></li>
						</ul>
					</div>
				</div>
			</section>
		)
	}

}

export default Logos;