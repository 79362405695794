import React, { Component } from 'react'
import Header from '../header'
import Footer from '../footer'

import './index.scss'

class Merchant extends Component {

		constructor(props) {
			super(props);
	
			this.state = {
				viewAuth: false,
				headerBackground: false,
			};
	
		}
	
		render() {
			const {
				viewAuth,
			} = this.state;
	
			const { includeToCabinet } = this.props;
	
			return (
				<div className="main">
					{!includeToCabinet ?
						<Header 
							viewAuth={viewAuth}
							openAuth={() => this.setState({viewAuth: !viewAuth})} 
						/>
					: false}
					<section className="merchant">
						<div className="container">
							<div className="merchant__wrapper">
								<div className="merchant__icon">
									{this.props.match.params.status === "success" ? <i className="fa fa-check-circle success_icon" aria-hidden="true"></i> : <i className="fa fa-times-circle fail_icon" aria-hidden="true"></i>}
								</div>
								<div className="merch_mess">
									<p>{this.props.match.params.status === "success" ? "Успешное пополнение" : "Ошибка пополнения"}</p>
								</div>
								<a className="btn btn_border link_back" href="/cabinet/profile">Вернуться назад</a>
							</div>
						</div>
					</section>
				{!includeToCabinet ? <Footer /> : false}

				</div>
			)
		}
	
	}


export default Merchant;
